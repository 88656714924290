import React from 'react';
import './CardSectionStyles.css'
import MyUrlField from './MyUrlField'

import {
    List, Datagrid, TextField,
    TextInput, Filter
} from 'react-admin';

// import MyUrlField from './MyUrlField';

const ConnectionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

// const UrlField = ({ record, source }) => <a href={
//     record[source]
//   }>{record[source]}</a>;

export const ConnectionList = props => (
    <List filters={<ConnectionFilter />} bulkActionButtons={false} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid>
            <TextField source="vpn_id" />
            <TextField source="peer" />
            <TextField source="endpoint" />
            {/* <TextField source="addr" /> */}
            <MyUrlField source="addr" />
            <TextField source="transfer_in" />
            <TextField source="transfer_out" />
            <TextField source="latest_handshake" />
        </Datagrid>
    </List>
);
