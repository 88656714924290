import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/AttachMoney';
// import ServerSecurityIcon from 'mdi-react/ServerSecurityIcon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CardIcon from './CardIcon';

interface Props {
    value?: number;
}

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    title: {},
});

const MonthlyRevenue: FC<Props> = ({ value }) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <CardIcon Icon={DollarIcon} bgColor="#2e55d5" />
            {/* <ServerSecurityIcon color="#2e55d5" /> */}
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {"Total Connections"}
                </Typography>
                <Typography variant="h5" component="h2">
                    {value}
                </Typography>
            </Card>
        </div>
    );
};

export default MonthlyRevenue;
