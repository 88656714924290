import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Title } from 'react-admin';
import MonthlyRevenue from './MonthlyRevenue';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

// export default () => (
const Dash = () => (
    <Card>
        <Title title="WishVPN Dashboard" />
        <CardHeader title="Welcome to WishVPN" />
        <CardContent>
            {/* <div>Your VPN managed</div> */}
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <MonthlyRevenue value={1000} />
                        <MonthlyRevenue value={2000} />
                    </div>
                </div>
            </div>
        </CardContent>
    </Card>
);

export default Dash;
