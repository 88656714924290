import React from 'react';

import {
    List, Datagrid, TextField, BooleanField,
    SimpleForm, TextInput, Edit, Create, Filter
} from 'react-admin';

const OrganizationsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="Organizations" source="OrganizationsId" reference="Organizationss" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

export const OrganizationsList = props => (
    <List filters={<OrganizationsFilter />} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid>
            <TextField source="name" />
            {/* <EditButton /> */}
        </Datagrid>
    </List>
);

const OrganizationsName = ({ record }) => {
    return <span>Organizations {record ? `"${record.name}"` : ''}</span>;
};

export const OrganizationsEdit = props => (
    <Edit title={<OrganizationsName />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <BooleanField source="active" />
            <TextInput source="name" />
            <TextInput source="ip" />
            <TextInput source="mgmt" />
        </SimpleForm>
    </Edit>
);

export const OrganizationsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="ip" />
            <TextInput source="mgmt" />
        </SimpleForm>
    </Create>
);
