import React from 'react';

import {
    List, Datagrid, TextField, BooleanField, EditButton,
    SimpleForm, TextInput, Edit, Create, Filter
} from 'react-admin';

const NodeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="Node" source="NodeId" reference="Nodes" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

export const NodeList = props => (
    <List filters={<NodeFilter />} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="name" />
            <BooleanField source="active" />
            <TextField source="ip" />
            <TextField source="mgmt" />
            <EditButton />
        </Datagrid>
    </List>
);

const NodeName = ({ record }) => {
    return <span>Node {record ? `"${record.name}"` : ''}</span>;
};

export const NodeEdit = props => (
    <Edit title={<NodeName />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <BooleanField source="active" />
            <TextInput source="name" />
            <TextInput source="ip" />
            <TextInput source="mgmt" />
        </SimpleForm>
    </Edit>
);

export const NodeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="ip" />
            <TextInput source="mgmt" />
        </SimpleForm>
    </Create>
);
