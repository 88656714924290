import React from 'react';
// import { PasswordInput } from 'react-admin';
// import { SelectArrayInput } from 'react-admin';
import { DateField, BooleanField } from 'react-admin';
// import { loadStripe } from '@stripe/stripe-js';
import Button from '@material-ui/core/Button';

import {
    List, Datagrid, TextField,
    SimpleForm, TextInput, Edit, Create, Filter
} from 'react-admin';

// import MyUrlField from './MyUrlField';

// const stripePromise = loadStripe('pk_test_Z0DAcCxJLppXqUwAjZWJ7ZuF00QSaGDaqJ');

const OrderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="Order" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

const PayButton = ({ record, ...rest }) =>
    record && !(record.payment)
        ? (
            <Button variant="contained" color="primary" href="https://stripe.com">Pay</Button>
        )
        : null;

export const OrderList = props => (
    <List filters={<OrderFilter />} bulkActionButtons={false} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid>
            {/* <TextField source="id" /> */}
            {/* <TextField label="Org Name" source="org_name" /> */}
            <DateField label="Begin Date" source="begin_date" />
            <DateField label="End Date" source="end_date" />
            <TextField label="Pricing" source="pricing" />
            <TextField label="Tier" source="tier" />
            <TextField label="Price" source="price" />
            <BooleanField label="Payment" source="payment" />
            <PayButton />
        </Datagrid>
    </List>
);

const OrderName = ({ record }) => {
    return <span>Order {record ? `"${record.email}"` : ''}</span>;
};

export const OrderEdit = props => (
    <Edit title={<OrderName />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
        </SimpleForm>
    </Edit>
);

export const OrderCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email" />
        </SimpleForm>
    </Create>
);
