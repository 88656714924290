import React from 'react';
// import ReactDOM from 'react-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import {
//     CardElement,
//     Elements,
//     useStripe,
//     useElements,
// } from '@stripe/react-stripe-js';
import './CardSectionStyles.css'

import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TuneIcon from '@material-ui/icons/Tune';
import { SiOpenvpn } from "react-icons/si";
import { SiWireguard } from "react-icons/si";
import { Box, Divider } from '@material-ui/core';

import {
    List, Datagrid, TextField, BooleanField,
    SimpleForm, TextInput, Edit, Create, Filter, required,
    SelectInput, CheckboxGroupInput, Toolbar,
    SimpleShowLayout, ShowController, ShowView,
    FormDataConsumer, TabbedForm, FormTab,
    EditButton, SaveButton, Confirm, BooleanInput, NumberInput,
    regex, PasswordInput,
    ArrayInput, SimpleFormIterator
} from 'react-admin';

import { useFormState } from 'react-final-form';
// import { useField } from 'react-final-form';

import { generateKeypair } from './wireguard.js';

const validateB64 = regex(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/, 'Must be a valid Base64 key');

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomDivider = () => (
    <Box mb={2}>
        <Divider />
    </Box>
);

const DeploymentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="Deployment" source="DeploymentId" reference="Deployments" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

// const CARD_ELEMENT_OPTIONS = {
//     style: {
//         base: {
//             color: "#32325d",
//             fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//             fontSmoothing: "antialiased",
//             fontSize: "16px",
//             "::placeholder": {
//                 color: "#aab7c4",
//             },
//         },
//         invalid: {
//             color: "#fa755a",
//             iconColor: "#fa755a",
//         },
//     },
// };

export const DeploymentList = props => (
    <List filters={<DeploymentFilter />} bulkActionButtons={false} exporter={false} {...props}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="host" />
            <BooleanField label="WireGuard" source="wg" />
            <BooleanField label="OpenVPN" source="ovpn" />
            <TextField source="organization_id" />
            <TextField label="Country" source="geoloc" />
            <EditButton />
            {/* <ShowButton /> */}
            {/* <DeleteWithConfirmButton confirmTitle="Delete VPN" confirmContent="Are you sure you want to delete this VPN" /> */}
        </Datagrid>
    </List>
);

export const DeploymentShow = props => (
    <ShowController {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <TextField source="peer" label="User/Peer" />
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
);


const DeploymentName = ({ record }) => {
    return <span>Deployment {record ? `"${record.name}"` : ''}</span>;
};

export const DeploymentEdit = props => (
    <Edit title={<DeploymentName />} {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <TextInput label="Host" source="host" validate={required()} />
            <CheckboxGroupInput source="category" label="VPN Type" validate={required()} choices={[
                { id: 'wg', name: 'Wireguard' },
                { id: 'ovpn', name: 'OpenVPN' },
            ]} />
            <SelectInput source="geoloc" label="Location" validate={required()} choices={[
                { id: 'ovh_it', name: '🇮🇹 Italy' },
                { id: 'ovh_fr', name: '🇫🇷 France' },
                { id: 'ovh_es', name: '🇪🇸 Spain' },
            ]} />
        </SimpleForm>
    </Edit>
);

// function customAction() {

// }

const CreateButton = ({ handleSubmitWithRedirect, handleSubmit, ...props }) => {
    // const form = useForm();
    // const notify = useNotify();
    const [open, setOpen] = React.useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        // doStuff();
        // notify('Stuff is done.');
        handleSubmit();
        setOpen(false);
    };

    var ct = "Do you really want to create the VPN server? This will generate an order to pay";
    return (<><SaveButton {...props} label="Create VPN" icon={<AddIcon />} handleSubmitWithRedirect={handleClick} handleSubmit={handleClick} />
        <Confirm
            isOpen={open}
            title="Create VPN"
            content={ct}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
            confirmColor="primary"
            confirm="Yes"
            cancel="No"
        />
    </>);
}

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <CreateButton />
    </Toolbar>
);

export const DeploymentCreate = props => (
    <Create {...props}>
        <TabbedForm toolbar={<CustomToolbar alwaysEnableSaveButton />}>
            <FormTab label="deployment" icon={<VpnKeyIcon />}>
                <TextInput label="Name" source="vpn_name" validate={required()} />
                <SelectInput source="geoloc" label="Location" validate={required()} choices={[
                    { id: 'ovh_it', name: '🇮🇹 Italy (OVH)' },
                    { id: 'ovh_fr', name: '🇫🇷 France (OVH)' },
                    { id: 'ovh_es', name: '🇪🇸 Spain (OVH)' },
                ]} initialValue='ovh_fr' />
                <SelectInput source="tier" label="Plan" validate={required()} choices={[
                    { id: 't1', name: '50MBit/s - 1TB - €39/mo' },
                    { id: 't2', name: '100MBit/s - 2TB - €49/mo' },
                    { id: 't3', name: '200MBit/s - 4TB - €59/mo' },
                ]} initialValue='t2' />
            </FormTab>

            <FormTab label="Wireguard" icon={<SiWireguard size="1.5em" />}>
                <BooleanInput label="Enabled" source="wg_enabled" initialValue={true} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.wg_enabled === true ? (
                        <div style={{ width: '100%' }}>
                            <CustomDivider />
                            <Box>
                                <TextInput label="Addr" source="wg_srv_addr" initialValue='10.10.0.1/16' validate={required()} {...rest} />
                            </Box>
                            <Box>
                                <NumberInput label="Listen Port" source="wg_srv_port" initialValue='51820' validate={required()} {...rest} />
                            </Box>
                            <Box>
                                <BooleanInput label="NAT" source="wg_nat" initialValue={true} {...rest} />
                            </Box>
                            <ArrayInput label="Static Routes" source="wg_routes">
                                <SimpleFormIterator>
                                    <TextInput label="Gateway" source="gateway" validate={required()} />
                                    <TextInput label="Destination" source="destination" validate={required()} />
                                    <NumberInput label="Metric" source="metric" validate={required()} />
                                    <BooleanInput label="OnLink Route" source="onlink" initialValue={true} />
                                </SimpleFormIterator>
                            </ArrayInput>
                            <CustomDivider />
                            <WGKeysInput {...rest}/>
                        </div>
                    ) : null
                    }
                </FormDataConsumer>
            </FormTab>

            <FormTab label="OpenVPN" icon={<SiOpenvpn size="1.5em" />}>
                <BooleanInput label="Enabled" source="ovpn_enabled" initialValue={false} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.ovpn_enabled === true ? (
                        <div style={{ width: '100%' }}>
                            <CustomDivider />
                            <Box>
                                <TextInput label="Addr" source="ovpn_srv_addr" initialValue='10.11.0.1/16' validate={required()} {...rest} />
                            </Box>
                            <Box>
                                <NumberInput label="Listen Port" source="ovpn_srv_port" initialValue='1194' validate={required()} {...rest} />
                            </Box>
                            <Box>
                                <SelectInput source="ovpn_proto" label="Protocol" validate={required()} choices={[
                                    { id: 'tcp', name: 'TCP' },
                                    { id: 'udp', name: 'UDP' },
                                ]} initialValue='udp' {...rest} />
                            </Box>
                            <Box>
                                <BooleanInput label="NAT" source="ovpn_nat" initialValue={true} />
                            </Box>
                            <ArrayInput label="Static Routes" source="ovpn_routes">
                                <SimpleFormIterator>
                                    <TextInput label="Gateway" source="gateway" validate={required()} />
                                    <TextInput label="Destination" source="destination" validate={required()} />
                                    <NumberInput label="Metric" source="metric" validate={required()} />
                                    <BooleanInput label="OnLink Route" source="onlink" initialValue={true} />
                                </SimpleFormIterator>
                            </ArrayInput>
                            <CustomDivider />
                            <Box>
                                <TextInput label="CA" multiline source="ovpn_ca" validate={[required(), validateB64]} {...rest} />
                            </Box>
                            <Box>
                                <TextInput label="DH" multiline source="ovpn_dh" validate={[required(), validateB64]} {...rest} />
                            </Box>
                            <Box>
                                <TextInput label="Cert" multiline source="ovpn_cert" validate={[required(), validateB64]} {...rest} />
                            </Box>
                            <Box>
                                <TextInput label="Key" multiline source="ovpn_key" validate={[required(), validateB64]} {...rest} />
                            </Box>
                            <Box mb={3}>
                                <Button
                                    {...rest}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    // className={classes.button}
                                    startIcon={<VpnKeyIcon />}
                                // onClick={generateWGkeys}

                                >
                                    Generate
                                </Button>
                            </Box>
                            <CustomDivider />
                            <ArrayInput label="Push Routes" source="ovpn_push_routes">
                                <SimpleFormIterator>
                                    <TextInput label="Destination" source="destination" validate={required()} />
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput label="Push DNS" source="ovpn_push_dns_server">
                                <SimpleFormIterator>
                                    <TextInput label="IP" source="ip" validate={required()} />
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput label="Push DOMAINS" source="ovpn_push_dns_domain">
                                <SimpleFormIterator>
                                    <TextInput label="Domain" source="domain" validate={required()} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </div>
                    ) : null
                    }
                </FormDataConsumer>
            </FormTab>

            <FormTab label="Advanced" icon={<TuneIcon />}>
                <BooleanInput label="IP Forwarding" source="ip_forw" initialValue={true} />
            </FormTab>
        </TabbedForm>
    </Create>
);

// const CheckoutForm = () => {
//     const stripe = useStripe();
//     const elements = useElements();

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         const { error, paymentMethod } = await stripe.createPaymentMethod({
//             type: 'card',
//             card: elements.getElement(CardElement),
//         });
//     };

//     return (
//         <form onSubmit={handleSubmit}>
//             <label>
//                 Card details
//       <CardElement options={CARD_ELEMENT_OPTIONS} />
//             </label>
//             <button type="submit" disabled={!stripe}>
//                 Pay
//       </button>
//         </form>
//     );
// };

// const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');


// const generateWGkeys = () => {
//     var keypair = generateKeypair();

//     console.log(keypair)
// }

var first_time = true;

const WGKeysInput = props => {
    const { values } = useFormState();

    // const {
    //     input: { wg_priv_key, onChange, ...rest },
    //     meta: { touched, error },
    //     isRequired
    // } = useInput(props);

    // console.log(props);

    // const {
    //     input: { onChange },
    //     meta: { touched, error }
    // } = useField(values);

    const generateWGkeys = () => {
        var keypair = generateKeypair();
        values.wg_priv_key = keypair.privateKey;
        values.wg_pub_key = keypair.publicKey;
    }

    if (first_time === true) {
        first_time = false;
        generateWGkeys();
    }

    return (
        <div>
            <Box>
                <PasswordInput label="Private Key" source="wg_priv_key" validate={[required(), validateB64]} {...props}/>
            </Box>
            <Box>
                <TextInput label="Public Key" source="wg_pub_key" validate={[required(), validateB64]} {...props}/>
            </Box>
            <Box mb={3}>
                <Button
                    {...props}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<VpnKeyIcon />}
                    onClick={generateWGkeys}
                >
                    Generate
                </Button>
            </Box>
        </div>
    );
};
