// in src/MyAppBar.js
import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PublicIcon from '@material-ui/icons/Public';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import { SelectInput, SimpleForm } from 'react-admin';
// import { useSelector } from 'react-redux';

import Menu from '@material-ui/core/Menu';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// import MyUserMenu from './MyUserMenu';

// import Logo from './Logo';

// const BootstrapInput = withStyles((theme) => ({
//     root: {
//         'label + &': {
//             marginTop: theme.spacing(3),
//         },
//     },
//     input: {
//         borderRadius: 4,
//         position: 'relative',
//         backgroundColor: theme.palette.background.paper,
//         border: '1px solid #ced4da',
//         fontSize: 16,
//         padding: '10px 26px 10px 12px',
//         transition: theme.transitions.create(['border-color', 'box-shadow']),
//         // Use the system font instead of the default Roboto font.
//         fontFamily: [
//             '-apple-system',
//             'BlinkMacSystemFont',
//             '"Segoe UI"',
//             'Roboto',
//             '"Helvetica Neue"',
//             'Arial',
//             'sans-serif',
//             '"Apple Color Emoji"',
//             '"Segoe UI Emoji"',
//             '"Segoe UI Symbol"',
//         ].join(','),
//         '&:focus': {
//             borderRadius: 4,
//             borderColor: '#80bdff',
//             boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//         },
//     },
// }))(InputBase);

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    language: {
        margin: theme.spacing(0, 0.5, 0, 1),
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
}));

const MyAppBar = props => {
    const classes = useStyles();

    // const t = useSelector((state) => state.options.t);
    // const userLanguage = useSelector((state) => state.options.userLanguage);

    const [languageMenu, setLanguageMenu] = React.useState(null);
    // const [selectedNode, setSelectedNode] = React.useState("Node1");
    const [setSelectedNode] = React.useState("Node1");



    // const handleLanguageIconClick = (event) => {
    //     setLanguageMenu(event.currentTarget);
    // };

    const handleLanguageMenuClose = (event) => {
        setLanguageMenu(null);
    };

    const handleLanguageMenuClose1 = (event) => {
        // if (event.currentTarget.nodeName === 'A') {
        //   document.cookie = `userLanguage=noDefault;path=/;max-age=31536000`;
        // }
        setLanguageMenu(null);
        setSelectedNode("Node1");
    };

    const handleLanguageMenuClose2 = (event) => {
        // if (event.currentTarget.nodeName === 'A') {
        //   document.cookie = `userLanguage=noDefault;path=/;max-age=31536000`;
        // }
        setLanguageMenu(null);
        setSelectedNode("Node2");
    };

    return (
        <AppBar {...props}
            // userMenu={<MyUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {/* <Logo /> */}
            {/* WishVPN */}
            <span className={classes.spacer} />
            {/* <SimpleForm className={classes.formControl}>
                <SelectInput source="category" choices={[
                    { id: 'programming', name: 'Programming' },
                    { id: 'lifestyle', name: 'Lifestyle' },
                    { id: 'photography', name: 'Photography' },
                ]} />
            </SimpleForm> */}

            {/* <Tooltip title={"Select Node"} enterDelay={300}>
                <Button
                    color="inherit"
                    aria-owns={languageMenu ? 'language-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleLanguageIconClick}
                    data-ga-event-category="header"
                    data-ga-event-action="language"
                >
                    <DnsIcon />
                    <span className={classes.language}>
                        
                        { selectedNode }
                    </span>
                    <ExpandMoreIcon fontSize="small" />
                </Button>
            </Tooltip> */}

            <Menu
                id="language-menu"
                anchorEl={languageMenu}
                open={Boolean(languageMenu)}
                onClose={handleLanguageMenuClose}
            >
                <MenuItem
                    component="a"
                    data-no-link="true"
                    // href={language.code === 'en' ? canonical : `/${language.code}${canonical}`}
                    // key={"Node1"}
                    // selected={userLanguage === language.code}
                    onClick={handleLanguageMenuClose1}
                // lang={language.code}
                // hrefLang={language.code}

                >
                    Node1
                </MenuItem>
                <MenuItem
                    component="a"
                    data-no-link="true"
                    // href={language.code === 'en' ? canonical : `/${language.code}${canonical}`}
                    // key={"Node2"}
                    // selected={userLanguage === language.code}
                    onClick={handleLanguageMenuClose2}
                // lang={language.code}
                // hrefLang={language.code}
                >
                    Node2
                </MenuItem>
            </Menu>

            <Tooltip title="Notifications">
                <IconButton color="inherit">
                    <NotificationsIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Website">
                <IconButton color="inherit" href="https://www.wishvpn.com">
                    <PublicIcon />
                </IconButton>
            </Tooltip>
            {/* <Tooltip title="Website2">
                <IconButton color="inherit" onClick={() => window.open('https://www.wishvpn.com', "_blank")}>
                    <PublicIcon />
                </IconButton>
            </Tooltip> */}
        </AppBar>
    );
};

export default MyAppBar;
