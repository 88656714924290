import React from 'react';
import { PasswordInput } from 'react-admin';
import { SelectArrayInput } from 'react-admin';

import {
    List, Datagrid, EmailField, EditButton,
    SimpleForm, TextInput, Edit, Create, Filter
} from 'react-admin';

// import MyUrlField from './MyUrlField';

const AccountFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="Account" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

export const AccountList = props => (
    <List filters={<AccountFilter />} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid>
            {/* <TextField source="id" /> */}
            <EmailField source="email" />
            <EditButton />
        </Datagrid>
    </List>
);

const AccountName = ({ record }) => {
    return <span>Account {record ? `"${record.email}"` : ''}</span>;
};

export const AccountEdit = props => (
    <Edit title={<AccountName />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput disabled source="email" />
            <PasswordInput source="password" />
            <SelectArrayInput label="Roles" source="roles" choices={[
                { id: 'admin', name: 'Admin' },
                { id: 'monitor', name: 'Monitoring' },
                { id: 'accounts', name: 'Accounts' },
                { id: 'vpn_provisioning', name: 'Provisioning' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const AccountCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <PasswordInput source="password" />
            <SelectArrayInput label="Roles" source="roles" choices={[
                { id: 'admin', name: 'Admin' },
                { id: 'monitor', name: 'Monitoring' },
                { id: 'accounts', name: 'Accounts' },
                { id: 'vpn_provisioning', name: 'Provisioning' },
            ]} />
        </SimpleForm>
    </Create>
);
