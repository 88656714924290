import decodeJwt from 'jwt-decode';

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request('/api/1/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                const permissions = { 'roles': decodedToken.roles,
                'orgs': decodedToken.orgs,
                'current_org': decodedToken.current_org,
                'account_id': decodedToken.account_id,
                'username': decodedToken.username,
            };
                localStorage.setItem('permissions', JSON.stringify(permissions));
            });
    },
    logout: () => {
        var Token = localStorage.getItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        const request = new Request('/api/1/logout', {
            method: 'GET',
            headers: new Headers({ 'Authorization': 'Bearer ' + Token }),
        });
        return fetch(request)
            .then(response => {
                // if (response.status < 200 || response.status >= 300) {
                //     throw new Error(response.statusText);
                // }
                return;
            });
    },
    checkAuth: params => {
        return localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // getPermissions: params => Promise.resolve(),
    getPermissions: () => {
        const permissions = localStorage.getItem('permissions');
        const r = JSON.parse(permissions);
        return r ? Promise.resolve(r) : Promise.reject();
    },
    getIdentity: () => {
        try {
            const { account_id, username } = JSON.parse(localStorage.getItem('permissions'));
            const id = account_id;
            const fullName = username;
            return Promise.resolve({ id, fullName });
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

export default authProvider;
