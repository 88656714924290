import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2,
    },
});

const MyUrlField = ({ record = {}, source }) => {
    const classes = useStyles();
    var link = "http://" + record[source].slice(0, -3);
    return (
        <a href={link} rel="noreferrer" target="_blank" className={classes.link}>
            {record[source]}
            <LaunchIcon className={classes.icon} />
        </a>
    );
}

export default MyUrlField;
