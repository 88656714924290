import React from 'react';

import {
    List, Datagrid, TextField, EditButton,
    SimpleShowLayout, ShowView, ShowController,
    SimpleForm, TextInput, Edit, Create, Filter,
    required, regex, NumberInput, Toolbar, SaveButton,
    TabbedForm, FormTab, ArrayInput,
    SimpleFormIterator
} from 'react-admin';

// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AddIcon from '@material-ui/icons/Add';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import { SiOpenvpn } from "react-icons/si";
import { SiWireguard } from "react-icons/si";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

// import MyUrlField from './MyUrlField';

// import QRCode from "qrcode.react";

import { FunctionField } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
// import { useRecordContext } from 'react-admin';
import { SelectInput } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
import isIp from 'is-ip';
// import { generateKeypair } from './wireguard.js';

import { Box, Divider } from '@material-ui/core';

import { ReferenceInput } from 'react-admin';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomDivider = () => (
    <Box mb={2}>
        <Divider />
    </Box>
);

const validateB64 = regex(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/, 'Must be a valid Base64 key');

const myIsIp = (value, allValues) => {
    if (isIp(value) === true) {
        return undefined;
    }
    return 'Must be a valid IP address';
}
const validateIp = [myIsIp, required()];

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="User" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

export const UserList = props => (
    <List filters={<UserFilter />} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid rowClick="show">
            <TextField source="peer" label="User/Peer" />
            <FunctionField
                label="VPN Type"
                render={record => record.vpn_user_type === "wg" ? "wireguard" : "openvpn"}
            />;
            <FunctionField
                label="Pub Key"
                render={record => (
                    <Tooltip title={record.pub_key} >
                        <span className="table-cell-trucate">{record.pub_key ? record.pub_key.substr(0, 10) + "..." : null}</span>
                    </Tooltip>
                )}
            />
            <TextField source="ip" label="IP" />
            <EditButton />
            {/* <ShowButton /> */}
        </Datagrid>
    </List>
);

const UserName = ({ record }) => {
    return <span>User {record ? `"${record.email}"` : ''}</span>;
};

export const UserEdit = props => (
    <Edit title={<UserName />} {...props}>
        <SimpleForm>
            <SelectInput label="VPN Type" disabled initialValue="wg" allowEmpty={false} source="vpn_user_type" choices={[
                { id: 'wg', name: 'wireguard' },
                { id: 'ovpn', name: 'openvpn' },
            ]} />
            <TextInput label="User/Peer" source="peer" validate={required()} />
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.vpn_user_type === "wg" &&
                    <TextInput label="Pub Key" source="pub_key" validate={[required(), validateB64]} {...rest} />
                }
            </FormDataConsumer>
            <TextInput label="IP" source="ip" validate={validateIp} />
        </SimpleForm>
    </Edit>
);

// var wgpubkey = "";


// const generateWGkeys = () => {
//     var keypair = generateKeypair();
//     // generateKeypair;

//     // var pub_key = keypair.publicKey;
//     var priv_key = keypair.privateKey;
//     var srv_pub_key = "6tOmsV9R1Ej/pNIuiyNZHrMMfYyW/K+aZ6lTGs0lY24=";

//     var wgpubkey_tpl = `[Interface]\n\
// PrivateKey = ${priv_key}\n\
// Address = 10.0.2.20/24\n\
// \n\
// [Peer]\n\
// PublicKey = ${srv_pub_key}\n\
// AllowedIPs = 0.0.0.0/0\n\
// Endpoint = vpn1.wishvpn.com:51820\n\
// PersistentKeepalive = 25\n\
// `;

//     wgpubkey = wgpubkey_tpl;

//     console.log(wgpubkey_tpl)
// }

// const getWGkey = () => {
//     return wgpubkey;
// }

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton {...props} label="Create" icon={<AddIcon />} />
    </Toolbar>
);

export const UserCreate = props => (
    <Create {...props}>
        <TabbedForm toolbar={<CustomToolbar alwaysEnableSaveButton />}>
            <FormTab label="User" icon={<PermIdentityIcon />}>
                <TextInput label="User/Peer" source="peer" validate={required()} />
                <TextInput label="Email" source="email" />
                <NumberInput label="Uplink MBit/s" step={1} min={1} max={1000} source="up_mbits" />
                <NumberInput label="Downlink MBit/s" step={1} min={1} max={1000} source="down_mbits" />
                {/* <NumberInput label="Uplink MB" step={1} min={1} max={1000} source="up_mb" />
                <NumberInput label="Downlink MB" step={1} min={1} max={1000} source="down_mb" /> */}
                {/* <Box flexDirection="row" bgcolor="background.paper" >
                    <Box>
                        <ReferenceInput label="VPN" source="name" reference="vpns">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </Box>
                    <Box>
                        <QRCode value={getWGkey()} />
                    </Box>
                </Box> */}
            </FormTab>
            <FormTab label="Wireguard" icon={<SiWireguard size="1.5em" />}>
                <Box flexDirection="row" bgcolor="background.paper" >
                    <Box>
                        <TextInput label="Pub Key" source="pub_key" validate={[required(), validateB64]} />
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            // className={classes.button}
                            startIcon={<VpnKeyIcon />}
                        // onClick={generateWGkeys}
                        >
                            Generate
                        </Button>
                    </Box>
                </Box>
                <TextInput label="Client IP" source="wg_dflt_client_ip" validate={validateIp} />
                <ArrayInput label="Server Specific Config" source="wg_per_server">
                    <SimpleFormIterator>
                        <ReferenceInput label="VPN" source="name" reference="vpns">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <TextInput label="Client IP" source="client_ip" validate={validateIp} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="OpenVPN" icon={<SiOpenvpn size="1.5em" />}>
                <Box flexDirection="row" bgcolor="background.paper" >
                    <TextInput label="CSR" source="csr" validate={[required(), validateB64]} />
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        // className={classes.button}
                        startIcon={<VpnKeyIcon />}
                    // onClick={generateWGkeys}
                    >
                        Generate
                    </Button>
                </Box>
                <TextInput label="Client IP" source="ovpn_dflt_client_ip" validate={validateIp} />
                <ArrayInput label="Server Specific Config" source="ovpn_per_server">
                    <SimpleFormIterator>
                        <ReferenceInput label="VPN" source="name" reference="vpns">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <TextInput label="Client IP" source="client_ip" validate={validateIp} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Advanced" icon={<TuneIcon />}>
                <SelectInput label="Server Access" initialValue="allow_all" allowEmpty={false} source="vpn_server_access" choices={[
                    { id: 'allow_all', name: 'Allow All' },
                    { id: 'deny_all', name: 'Deny All' },
                    { id: 'allow_list', name: 'Allow List' },
                    { id: 'deny_list', name: 'Deny List' },
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.vpn_server_access === 'allow_list' || formData.vpn_server_access === 'deny_list') ? (
                        <ArrayInput label="VPN Server List" source="vpn_list">
                            <SimpleFormIterator>
                                <ReferenceInput label="VPN" source="name" reference="vpns">
                                    <SelectInput optionText="name" />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    )
                        : null
                    }
                </FormDataConsumer>
                <CustomDivider />
                <SelectInput label="Firewall Access" initialValue="allow_all" allowEmpty={false} source="firewall_access" choices={[
                    { id: 'allow_all', name: 'Allow All' },
                    { id: 'deny_all', name: 'Deny All' },
                    { id: 'allow_list', name: 'Allow List' },
                    { id: 'deny_list', name: 'Deny List' },
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.firewall_access === 'allow_list' || formData.firewall_access === 'deny_list') ? (
                        <ArrayInput label="Users" source="firewall_reachability">
                            <SimpleFormIterator>
                                <ReferenceInput label="User" source="peer" reference="vpn_users">
                                    <SelectInput optionText="peer" />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    )
                        : null
                    }
                </FormDataConsumer>
                <CustomDivider />
                <SelectInput label="Web-based User Access" initialValue="deny_all" allowEmpty={false} source="device_access" choices={[
                    { id: 'allow_all', name: 'Allow All' },
                    { id: 'allow_monitoring', name: 'Allow Monitoring' },
                    { id: 'allow_list', name: 'Allow List' },
                    { id: 'deny_all', name: 'Deny All' },
                    { id: 'deny_list', name: 'Deny List' },
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.device_access === 'allow_list' || formData.device_access === 'deny_list') ? (
                        <ArrayInput label="Users" source="web_reachability">
                            <SimpleFormIterator>
                                <ReferenceInput label="User" source="email" reference="accounts">
                                    <SelectInput optionText="email" />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    )
                        : null
                    }
                </FormDataConsumer>
                <SelectInput label="HTTP Reverse Proxy" initialValue="disabled" allowEmpty={false} source="reverse_proxy" choices={[
                    { id: 'disabled', name: 'Disabled' },
                    { id: 'static_enabled', name: 'Enabled (Static)' },
                    { id: 'dynamic_enabled', name: 'Enabled (Dynamic)' },
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.reverse_proxy !== 'disabled' ? (
                        <NumberInput label="Port" source="reverse_proxy_port" initialValue='80' validate={required()} />
                    )
                        : null
                    }
                </FormDataConsumer>
                <SelectInput label="Web Based SSH" initialValue="disabled" allowEmpty={false} source="ssh_proxy" choices={[
                    { id: 'disabled', name: 'Disabled' },
                    { id: 'static_enabled', name: 'Enabled (Static)' },
                    { id: 'dynamic_enabled', name: 'Enabled (Dynamic)' },
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.ssh_proxy !== 'disabled' ? (
                        <NumberInput label="Port" source="ssh_proxy_port" initialValue='22' validate={required()} />
                    )
                        : null
                    }
                </FormDataConsumer>
            </FormTab>
        </TabbedForm>
    </Create>
);

// export const UserShow = (props) => (
//     <Show {...props}>
//         <SimpleShowLayout>
//             <TextField source="peer" label="User/Peer" />
//             <FunctionField
//                 label="VPN Type"
//                 render={record => record.vpn_user_type == "wg" ? "wireguard" : "openvpn"}
//             />;
//             <FunctionField
//                 label="Pub Key"
//                 render={record => (
//                     <TextField title={record.pub_key} >
//                         <span className="table-cell-trucate">{record.pub_key ? record.pub_key.substr(0, 10) + "..." : null}</span>
//                     </Tooltip>
//                 )}
//             />;
//             {/* <ConditionalPubKey label="Pub Key" {...props} /> */}
//             <TextField source="ip" label="IP" />
//             <TextField source="pub_key" label="AAA" />
//         </SimpleShowLayout>
//     </Show>
// );

export const UserShow = props => (
    <ShowController {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <TextField source="peer" label="User/Peer" />
                    <FunctionField
                        label="VPN Type"
                        render={record => record.vpn_user_type === "wg" ? "wireguard" : "openvpn"}
                    />
                    {/* {controllerProps.record && controllerProps.record.vpn_user_type == "wg" &&
                        <TextField source="pub_key" label="Pub Key" />
                    } */}
                    {controllerProps.record && controllerProps.record.vpn_user_type === "wg" &&

                        <TextField source="pub_key" label="Pub Key" props={props} />

                        // <CopyToClipboard text={controllerProps.record.pub_key}>

                        //     <Button>
                        //         {controllerProps.record.pub_key}
                        //     </Button>
                        // </CopyToClipboard>
                    }
                    {controllerProps.record && controllerProps.record.ip &&
                        <TextField source="ip" label="IP" />
                    }
                    {/* <TextField source="ip" label="IP" /> */}
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
);
