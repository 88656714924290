import React from 'react';

import {
    List, Datagrid, TextField,
    SimpleForm, TextInput, Edit, Create, Filter
} from 'react-admin';

// import MyUrlField from './MyUrlField';

const IPResourceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <ReferenceInput label="IPResource" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

export const IPResourceList = props => (
    <List filters={<IPResourceFilter />} exporter={false} {...props}>
        {/* <Datagrid rowClick="edit"> */}
        <Datagrid>
            <TextField label="Public IP" source="ip" />
            <TextField label="Org Name" source="org_name" />
        </Datagrid>
    </List>
);

const IPResourceName = ({ record }) => {
    return <span>IPResource {record ? `"${record.email}"` : ''}</span>;
};

export const IPResourceEdit = props => (
    <Edit title={<IPResourceName />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
        </SimpleForm>
    </Edit>
);

export const IPResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email" />
        </SimpleForm>
    </Create>
);
