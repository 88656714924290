// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import { UserShow, UserList, UserEdit, UserCreate } from './users';
import { AccountList, AccountEdit, AccountCreate } from './accounts';
import { OrderList } from './orders';
import { DeploymentList, DeploymentEdit, DeploymentCreate, DeploymentShow } from './deployments';
import { ConnectionList } from './connections';
import { NodeList, NodeEdit, NodeCreate } from './nodes';
import { OrganizationsList } from './organizations';
import { IPResourceList } from './ipresources';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Dashboard } from './dashboard';
import authProvider from './authProvider';
// import jsonServerProvider from 'ra-data-json-server';
// import ServerSecurityIcon from 'mdi-react/ServerSecurityIcon';
// import ServerNetworkIcon from 'mdi-react/ServerNetworkIcon';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import DevicesIcon from '@material-ui/icons/Devices';
// import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import DnsIcon from '@material-ui/icons/Dns';
import BusinessIcon from '@material-ui/icons/Business';
import simpleRestProvider from 'ra-data-simple-rest';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faErlang } from '@fortawesome/free-brands-svg-icons'
// import { Layout } from './layout';
import MyLayout from './MyLayout';


import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
// import profile from './profile';


const myTheme = createMuiTheme({
    palette: {
        primary: indigo,
        secondary: {
            main: '#2e55d5',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'gray', // Some CSS
            },
        },
    },
});


const fetchJson = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: "Bearer " + localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider('/api/1', fetchJson);

// const dataProvider = jsonServerProvider('/api/1');

const App = () => (
    <Admin
        // loginPage={WishLoginPage}
        disableTelemetry
        title="WishVPN"
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        theme={myTheme}
        layout={MyLayout}
        // customRoutes={[
        //     <Route
        //         key="my-profile"
        //         path="/configuration"
        //         component={profile.edit}
        //     />
        // ]}
    >

        {permissions => [
            <Resource name="vpns" show={DeploymentShow} list={DeploymentList} edit={DeploymentEdit} create={DeploymentCreate} icon={VpnLockIcon} />,
            <Resource name="vpn_users" show={UserShow} list={UserList} edit={UserEdit} create={UserCreate} icon={DevicesIcon} />,
            <Resource name="vpn_connections" list={ConnectionList} icon={SettingsEthernetIcon} />,
            <Resource name="accounts" list={AccountList} edit={AccountEdit} create={AccountCreate} icon={PersonIcon} />,
            <Resource name="orders" list={OrderList} icon={AttachMoneyIcon} />,

            // <Resource name="profile" />,

            permissions.roles.includes('wishem_admin')
                ? <Resource name="ip_resources" list={IPResourceList} icon={StorageIcon} />
                : null,

            permissions.roles.includes('wishem_admin')
                ? <Resource name="nodes" list={NodeList} edit={NodeEdit} create={NodeCreate} icon={DnsIcon} />
                : null,

            permissions.roles.includes('wishem_admin')
                ? <Resource name="organizations" list={OrganizationsList} icon={BusinessIcon} />
                : null,

        ]}
    </Admin>
);

export default App;
